import 'lazysizes'
import flatpickr from 'flatpickr'
import Swiper from 'swiper'
import Share from './js/components/social-share'

window.Swiper = Swiper
const shareModule = new Share('#social-share-layer', '.btn-return', '.btn-close')
shareModule.init()
if (document.getElementById('dateRange')) {
  function formatDate (date) {
    let dd = date.getDate()
    if (dd < 10) dd = '0' + dd
    let mm = date.getMonth() + 1
    if (mm < 10) mm = '0' + mm
    let yy = date.getFullYear()
    // if (yy < 10) yy = '0' + yy
    return `${yy}-${mm}-${dd}`
  }

  let datePickerElm = document.getElementById('dateRange')
  flatpickr(datePickerElm, {
    mode: 'range',
    dateFormat: 'M d, Y',
    wrap: true,
    onReady: function () {
      let start = new Date(urlParamsPress.get('dateStart'))
      let end = new Date(urlParamsPress.get('dateEnd'))
      end.setDate(end.getDate() - 1)
      if (urlParamsPress.get('dateStart'))
        this.setDate([start, end])
    },
    onClose: function (selectedDates) {
      let dateStart = new Date(selectedDates[0])
      let dateEnd = new Date(selectedDates[1])

      // if (dateStart.getTime() === dateEnd.getTime()) {
      dateEnd.setDate(dateEnd.getDate() + 1)
      // }

      if (selectedDates[0])
        urlParamsPress.set('dateStart', formatDate(dateStart).toString())
      else
        urlParamsPress.delete('dateStart')

      if (selectedDates[1])
        urlParamsPress.set('dateEnd', formatDate(dateEnd).toString())
      else
        urlParamsPress.delete('dateEnd')
        
      if (window.location.search.substr(1) !== urlParamsPress.toString())
        urlParamsPress.set('page', '1')
        window.location.search = urlParamsPress.toString()
    }
  })
}

if (document.getElementById('dateRangeStudio')) {
  let datePickerElm = document.getElementById('dateRangeStudio')
  let oneYearFromNow = new Date()
  let minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
  flatpickr(datePickerElm, {
    mode: 'multiple',
    dateFormat: 'm-d-Y',
    wrap: true,
    minDate: minDate,
    maxDate: oneYearFromNow,
    altInput: true,
    altFormat: 'M d Y',
    altInputClass: 'text-14 bg-light-gray dateValidate',
    onClose: () => checkValid(document.querySelector('.dateValidate'))
  })
}

window.onclick = function (event) {
  if(event.target.matches('.close-cookie-modal')) {
    $(event.target).closest('.cc-window').hide()
  } 
  if (!event.target.matches('.dropdown-trigger') && !event.target.matches('.modal-trigger')) {
    const dropdowns = $('.dropdown')

    for (let index = 0; index < dropdowns.length; index++) {
      const dropdown = dropdowns[index]

      if (dropdown.classList.contains('show')) {
        dropdown.classList.remove('show')
      }
    }

    $('.modal.show').removeClass('show')

    $('#dropdown-backlayer').removeClass('show')
  }
}

$('.dropdown').click(function (event) {
  if (!event.currentTarget.matches('.dropdown-react')) {
    $(event.currentTarget).toggleClass('show')
    $('#dropdown-backlayer').toggleClass('show')
  }
})

let lastScrollTop = 0
$('#main').scroll(function (e) {
  if (!$('#navbar').hasClass('no-scroll')) {
    if (!$('#navbar').hasClass('navbar-dark')) {
      _handleNavbarScroll(e)
    } else if ($('#navbar').hasClass('transparent')) {
      _handleTransparentNavbarScroll(e)
    }
  }

  if (window.location.pathname.includes('/articles/') || window.location.pathname.includes('/publications/')) {
    _handleArticleDetailScroll()
  }

  let st = e.target.scrollTop
  if (st > lastScrollTop) {
    $('#navbar').addClass('nav-scrollhide')
  } else {
    $('#navbar').removeClass('nav-scrollhide')
  }
  lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
})

const _handleNavbarScroll = function (e) {
  if (e.target.scrollTop > 64 && !$('#navbar').hasClass('navbar-highlighted')) {
    $('#navbar').toggleClass('navbar-highlighted')
    $('#navbar').hasClass('navbar-dark') && $('#search_button').addClass('btn-translucent')
  } else if (e.target.scrollTop <= 64 && $('#navbar').hasClass('navbar-highlighted')) {
    $('#navbar').toggleClass('navbar-highlighted')
    $('#navbar').hasClass('navbar-dark') && $('#search_button').removeClass('btn-translucent')
  }
}

const _handleTransparentNavbarScroll = function (e) {
  if (e.target.scrollTop > 32) {
    $('#navbar').css('background-color', 'white')
  } else if (e.target.scrollTop <= 32) {
    $('#navbar').css('background-color', 'transparent')
  }
}

$('#search_button').click(function () {
  if (window.location.href.includes('/search')) {
    $('#page_search_input').focus()
  } else {
    $('#navbar_search').addClass('show')
    $('#search_overlay').addClass('show')
    setTimeout(function () {
      $('#navbar_search_input').focus()
    }, 500)
  }
})

$('#mobile_search_button').click(function () {
  window.open('/search', '_self')
})

$('#go_search_button').click(function () {
  $('#navbar_search').removeClass('show')
  $('#search_overlay').removeClass('show')

  navigateToSearchPage()
})

$('#search_overlay').click(function () {
  $('#navbar_search').removeClass('show')
  $('#search_overlay').removeClass('show')
})

$('#side_menu_handle').click(function () {
  $('#side_menu').toggleClass('open')
})

$('#side_menu_close').click(function () {
  $('#side_menu').toggleClass('open')
})

$('#side_menu_close_responsive').click(function () {
  $('#side_menu').toggleClass('open')
})

$('#side_menu').click(function () {
  $('#side_menu').toggleClass('open')
})

$('#side_menu_content').click(function (e) {
  e.stopPropagation()
})

$('#navbar_menu_handle').click(function () {
  $('#side_menu').toggleClass('open')
})

$('#side_menu_content').scroll(function (e) {
  if (e.target.scrollTop > 30 && !$('#side_menu_scroll').hasClass('hidden')) {
    $('#side_menu_scroll').toggleClass('hidden')
  } else if (e.target.scrollTop <= 30 && $('#side_menu_scroll').hasClass('hidden')) {
    $('#side_menu_scroll').toggleClass('hidden')
  }
})

$('#guideline_load_btn').click(function () {
  $('#guideline_load_btn').hide()
  $('#guideline_gradient_overlay').hide()

  const guidelines = $('.competition-guidelines')
  const currentHeight = guidelines.height()
  guidelines.css({height: 'auto', marginBottom: 'unset'})
  const autoHeight = guidelines.height() + 80
  guidelines.height(currentHeight).animate({height: autoHeight, marginBottom: 'unset'}, 500)
})

$('#winners_load_btn').click(function () {
  $('#winners_load_btn').hide()
  $('#winners_gradient_overlay').hide()

  const guidelines = $('.grid')
  const currentHeight = guidelines.height()
  guidelines.css({height: 'auto', marginBottom: 'unset'})
  const autoHeight = guidelines.height()
  guidelines.height(currentHeight).animate({height: autoHeight}, 500)
})

$('.competition-past-winner').hover(function () {
  $('.competition-past-winner').toggleClass('inactive')
}, function () {
  $('.competition-past-winner').toggleClass('inactive')
})

$('.competition-winner-item').hover(function () {
  $('.competition-winner-item').toggleClass('inactive')
}, function () {
  $('.competition-winner-item').toggleClass('inactive')
})

// "Recent Winners" slider on Submissions landing page, similar to Chapter Events slider on Chapters page
$('.recent_winners_scroll_left').click(function () {
  const itemWidth = $('.competition-winner-item').width()

  $('#recent_winners_list').stop().animate({
    scrollLeft: '-=' + itemWidth + 'px'
  }, 'slow')
})

$('.recent_winners_scroll_right').click(function () {
  const itemWidth = $('.competition-winner-item').width()

  $('#recent_winners_list').stop().animate({
    scrollLeft: '+=' + itemWidth + 'px'
  }, 'slow')
})

$('#recent_winners_list').kinetic({})

$('#recent_winners_list').on('scroll', function () {
  const winnerList = $('#recent_winners_list')

  if (winnerList.scrollLeft() === 0) {
    $('.recent_winners_scroll_left').addClass('inactive')
    $('.recent_winners_scroll_right').removeClass('inactive')
  } else if (winnerList.scrollLeft() + winnerList.width() === winnerList.get(0).scrollWidth) {
    $('.recent_winners_scroll_left').removeClass('inactive')
    $('.recent_winners_scroll_right').addClass('inactive')
  } else {
    $('.recent_winners_scroll_left').removeClass('inactive')
    $('.recent_winners_scroll_right').removeClass('inactive')
  }
});
// End slider
// "Chapter Events" slider on Chapters page, similar to "Recent Winners" slider on Submissions page
const chapterEventsSlider = $('#chapter-events-slider');
if (chapterEventsSlider) {
  const chapterEventsLeftButton = $('.chapter_events_scroll_left');
  const chapterEventsRightButton = $('.chapter_events_scroll_right');

  chapterEventsSlider.kinetic({});

  $(chapterEventsLeftButton).click(function () {
    const itemWidth = $('.event-grid-item.slide').width()

    $(chapterEventsSlider).stop().animate({
      scrollLeft: '-=' + itemWidth + 'px'
    }, 'slow')
  })

  $(chapterEventsRightButton).click(function () {
    const itemWidth = $('.event-grid-item.slide').width()

    $(chapterEventsSlider).stop().animate({
      scrollLeft: '+=' + itemWidth + 'px'
    }, 'slow')
  })

  $(chapterEventsSlider).on('scroll', function () {

    if (chapterEventsSlider.scrollLeft() === 0) {
      $(chapterEventsLeftButton).addClass('inactive')
      $(chapterEventsRightButton).removeClass('inactive')
    } else if (chapterEventsSlider.scrollLeft() + chapterEventsSlider.width() === chapterEventsSlider.get(0).scrollWidth) {
      $(chapterEventsLeftButton).removeClass('inactive')
      $(chapterEventsRightButton).addClass('inactive')
    } else {
      $(chapterEventsLeftButton).removeClass('inactive')
      $(chapterEventsRightButton).removeClass('inactive')
    }
  });
}
// End slider

$('.article-gallery-item').hover(function () {
  $('.article-gallery-item').toggleClass('inactive')
}, function () {
  $('.article-gallery-item').toggleClass('inactive')
})

const articleDetailHeader = $('.article-detail-header')
const articleDetailSingleImage = $('.single-image')
const articleDetailLargeGallery = $('.large-gallery')
const articleDetailFeatured = $('.article-detail-featured')
const articleDetailFavoriteButton = $('#btn_article_detail_favorite')
const articleDetailReturnButton = $('#btn_article_detail_return')

const _handleArticleDetailScroll = function () {
  if (articleDetailFavoriteButton.offset().top >= articleDetailFeatured.offset().top - 400) {
    articleDetailFavoriteButton.addClass('fade-out')
    articleDetailReturnButton.addClass('fade-out')
  } else {
    articleDetailFavoriteButton.removeClass('fade-out')
    articleDetailReturnButton.removeClass('fade-out')

    let isWhiteMode = (articleDetailFavoriteButton.offset().top + 24 >= articleDetailHeader.offset().top && articleDetailFavoriteButton.offset().top + 24 <= articleDetailHeader.offset().top + articleDetailHeader.height())
      || (articleDetailFavoriteButton.offset().top + 24 >= articleDetailSingleImage.offset().top && articleDetailFavoriteButton.offset().top + 24 <= articleDetailSingleImage.offset().top + articleDetailSingleImage.height())
    if (isWhiteMode) {
      articleDetailFavoriteButton.addClass('btn-translucent image-white')
      articleDetailFavoriteButton.removeClass('btn-gray image-black')
    } else {
      articleDetailFavoriteButton.removeClass('btn-translucent image-white')
      articleDetailFavoriteButton.addClass('btn-gray image-black')
    }

    isWhiteMode = (articleDetailReturnButton.offset().top + 24 >= articleDetailHeader.offset().top && articleDetailReturnButton.offset().top + 24 <= articleDetailHeader.offset().top + articleDetailHeader.height())
      || (articleDetailReturnButton.offset().top + 24 >= articleDetailSingleImage.offset().top && articleDetailReturnButton.offset().top + 24 <= articleDetailSingleImage.offset().top + articleDetailSingleImage.height())
    if (isWhiteMode) {
      articleDetailReturnButton.addClass('btn-translucent image-white')
      articleDetailReturnButton.removeClass('btn-gray image-black')
    } else {
      articleDetailReturnButton.removeClass('btn-translucent image-white')
      articleDetailReturnButton.addClass('btn-gray image-black')
    }
  }
}

$('.home-add-to-calendar').click(function () {
  $(this).toggleClass('added')
})

for (let elm of document.querySelectorAll('.click-overview-list')) {
  let leftElements = $('.left')
  let listItems = $('.list-item')

  elm.addEventListener('click', function (e) {
    let currentIndex = elm.dataset.index
    for (let index = 0; index < leftElements.length; index++) {
      if (currentIndex - 1 === index) {
        leftElements[index].classList.add('active')
        listItems[index].classList.add('active')
      } else {
        leftElements[index].classList.remove('active')
        listItems[index].classList.remove('active')
      }
    }
  })
}

let searchType = ''
let searchTypeName = ''
let searchWord = ''

// Search Type Dropdown
$('.searchElm').on('click', function (e) {
  e.preventDefault()
  searchType = this.dataset.search
  searchTypeName = this.dataset.searchtypename
  $('#navbar_search_input, #page_search_input').attr('placeholder', 'Search ' + searchTypeName)
  $('.dropdown-trigger-text').text(searchTypeName)
  // Checks if this is the search page (and not the navbar search field)
  if ( $(this).parents('#search-page-form').length == 1 ) {
    searchWord = $('#page_search_input').val()
    // Filtering: If a keyword is specified, trigger search when dropdown changes
    if (searchWord != '') {
      window.open('/search?type=' + searchType + '&word=' + searchWord, '_self')
    }
  }
})

$('#page_search_input').on('keydown', function (e) {
  if (e.keyCode === 13 || e.which === 13) {
    let searchWord = e.target.value
    if (searchType.length < 1)
      searchType = searchTypePage
    window.open('/search?type=' + searchType + '&word=' + searchWord, '_self')
  }
})

$('.onPageSearch').on('click', function (e) {
  let searchWord = $('#page_search_input').val()
  if (searchType.length < 1)
    searchType = searchTypePage
  window.open('/search?type=' + searchType + '&word=' + searchWord, '_self')
})

$('#navbar_search_input').keydown(function (event) {
  if (event.keyCode === 13 || event.which === 13) {
    navigateToSearchPage()
  }
})

function navigateToSearchPage () {
  let searchWord = $('#navbar_search_input').val()

  if (searchType.length < 1)
    searchType = 'everything'

  window.open('/search?type=' + searchType + '&word=' + searchWord, '_self')
}

for (let form of document.querySelectorAll('.form')) {

  document.addEventListener('sent', e => {
    form.reset()
    console.log('Form send')
  })

  form.addEventListener('submit', (e) => {
    e.preventDefault()

    $('.form-success').hide()
    $('.form-error').hide()

    let message = new FormData(form)

    fetch('/sendMail.php', {
      method: 'POST',
      body: message
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        let sent = new Event('sent', {bubbles: true})

        if ([200, 201, 202, 204].includes(result)) {
          form.dispatchEvent(sent)
          $('.form-success').show()
        }

        if ([400, 401, 403, 406, 429, 500].includes(result)) {
          $('.form-error').show()
        }
      })
      .catch(error => console.log(error))
  })
}
$('.add-to-calendar-btn').on('click', function(event){
    let element = $(this);
    createRequest(element);
});
window.createRequest = function createRequest(element)
{   
    $.ajax({
        url: '/actions/events-helper-module/events-helper/index' ,
        method: 'POST',
        data: {id: $(element).data('id'), type: $(element).data('type'), event:$(element).data('event')},
        success: function (data) {
            let result = JSON.parse(data);
            console.log(result);
            if(!result.error){
                let a = document.createElement('a');
                a.setAttribute('href', result.url);
                a.setAttribute('download', result.filename);
                a.click();
                setTimeout(function(){  removeFile(result.file)}, 10000);
            }
        }
    });
}
function removeFile(file)
{
    $.ajax({
        url: '/actions/events-helper-module/events-helper/remove-file',
        method: 'POST',
        data: {file: file},
        success: function (result) {
            console.log('remove');
        }
    });
}

/**
 * If an accordion exists on the page, load the helper function which
 * toggles whether it is visible.
 */
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

document.addEventListener('DOMContentLoaded', function () {
  if(document.getElementsByClassName(".accordion-item")) {
    window.onToggleAccordionItem = function(index) {
      $('#accordionItem-' + index).toggleClass('expanded')
    }
  }
}, false);

/* Set a "close" cookie when the user closes a banner */
let banners = document.querySelectorAll('.global-banner');

console.log(banners);
if (banners.length > 0) {
  banners.forEach(banner => {
    let bannerId = banner.getAttribute('id');
    // First, check if they already have a cookie and, if so, do nothing; the banners are hidden by default
    let bannerCookie = getCookie(`close-${bannerId}`);
    // Get the close button for this banner
    let closeButton = banner.querySelector('.close-button');

    // Listen for a click to the close button, when we'll set the cookie
    closeButton.addEventListener('click', (e) => {
      setCookie(`close-${bannerId}`, true, 1);
      banner.classList.add('closed');
    });

    if (bannerCookie.length > 0) {
      // console.log(bannerId);
    } else {
      // Otherwise, open the banner
      banner.classList.remove('closed');
    }
  });
}